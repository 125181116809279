import { faAffiliatetheme, faAmazon } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "react-bootstrap";

interface Props {
    title: string;
    link: string;
    image: string;
    text: string;
}

export default function AmazonAffiliate(aff: Props) {

    return (
        <Card>
            <Card.Img variant="top" src={aff.image} title={aff.title} />
            <Card.Body>
                <Card.Title>{aff.title}</Card.Title>
                <Card.Text style={{fontSize: 'small'}}>{aff.text}</Card.Text>
                <Button variant="warning" block href={aff.link} target='_blank'><FontAwesomeIcon icon={faAmazon} style={{ marginRight: '0.5em' }} />Mehr Informationen</Button>
            </Card.Body>
        </Card>
    )
}