import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { history } from '../..';
import { useStore } from "../../app/stores/store";
import SearchBox from "./SearchBox";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { setPredicate } = blogStore;

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
        history.push("/artikel")
    }

    return (
        <Fragment>
            <Helmet>
                <title>Wellness Gesundheit Leben</title>      
                <meta name="description" content="Plattform mit Themen rund um das Fasten, Fitness und Gesundheit" />
                <meta name="keywords" content="wellness, gesundheit, leben" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Das Portal für Wellness und Wohlbefinden</h1>
            <AdvertOwnBlock
                title='Wellness, Wohlbefinden und Entspannung'
                content='Körperliches und geistiges Wohlbefinden sind Voraussetzungen für eine gesteigerte Lebensqualität. Entspannung, Bewegung, Ernährung und das soziale Umfeld bilden das Fundament für einen ganzheitlich ausgerichteten Lebensstil, der Körper, Geist und Seele positiv unterstützt.'
                image='/banners/wellness-banner.png'
                imageTarget='/wellness'
                targets={[
                    { linkText: 'Wellness', linkTarget: '/wellness' },
                    // { linkText: 'Gesund und Fit durch Fasten', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Suchmaschinenoptimierung', linkTarget: '/suchmaschinenoptimierung' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            {/* <AdvertsBlock advertCount={1} advertType={1} /> */}

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />
                
                <NewsletterSubscribe />

                <AmazonAffiliate
                    image='https://images-na.ssl-images-amazon.com/images/I/51MJ9YlAaZL._AC_SL1000_.jpg'
                    text='Luftbefeuchter Diffusor Aromatherapie Düfte Humidifier für ätherische Öle'
                    link='https://www.amazon.de/dp/B01B44UJ92/ref=as_li_ss_tl?dchild=1&keywords=wellness&qid=1623585142&sr=8-23&linkCode=ll1&tag=kayomo-21&linkId=fe094d059ace5f36443a5b4cb92dcc05&language=de_DE'
                    title='Aroma Diffuser'
                />

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}